import { createRouter as _createRouter, createWebHistory } from "vue-router";
import Auth from "@/services/authService.js";
import BrowserCheck from "@/services/browserCheck.js";

/** Module Routes */
import matterRoutes from "@/modules/matters/_routes/matterRoutes.js";
import sectorRoutes from "@/modules/sectors/_routes/SectorRoutes.js";
import deadlineCalculatorRoutes from "@/modules/deadline-calculator/_routes/deadlineCalculatorRoutes.js";
import terminologyRoutes from "@/modules/wiki/_routes/wikiRoutes.js";
import clientRoutes from "@/modules/clients/_routes/clientRoutes.js";
import peopleRoutes from "@/modules/people/_routes/peopleRoutes.js";
import blogRoutes from "@/modules/blogs/_routes/blogRoutes.js";
import hssRoutes from "@/modules/hss/_routes/hssRoutes.js";
import matterGroupsGenesisRoutes from "@/modules/genesis/_routes/matterGroupsGenesisRoutes.js";
import invitationsRoutes from "@/modules/invitations/_routes/invitationsRoutes.js";
import aiRoutes from "@/modules/hsf.ai/_routes/aiRoutes.js";

const UNAUTHORISED_PATH = "/unauthorised";

const baseRoutes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/dashboard",
    name: "home",
    component: () => import("@/views/HomeDashboard.vue"),
    meta: {
      title: "My Dashboard",
      darkBg: true,
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import("@/views/UserLoading.vue"),
    meta: {
      title: "Loading",
      noShell: true,
    },
  },
  {
    path: "/search/:q?/:e?",
    name: "search",
    component: () => import("@/modules/search/_views/SearchHome.vue"),
    meta: {
      title: "Search",
    },
    props: (route) => ({ q: route.query.q, e: route.query.e }),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/UserSettings.vue"),
    meta: {
      title: "User Settings",
    },
  },
  {
    path: "/system-status",
    name: "system-status",
    component: () => import("@/views/SystemStatus.vue"),
    meta: {
      title: "System Status",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/UserLogout.vue"),
    meta: {
      title: "Logout",
    },
  },
  {
    path: UNAUTHORISED_PATH,
    name: "401Unauthorised",
    component: () => import("@/views/401Unauthorised.vue"),
    meta: {
      title: "Unauthorised",
      noShell: true,
    },
  },
  {
    path: "/notifications/inbox",
    name: "notifications",
    component: () => import("@/views/NotificationInbox.vue"),
    meta: {
      title: "Notifications // Inbox",
    },
  },
];

// Catch All always goes last in the list of routes
const catchAll = [
  {
    path: "/:pathMatch(.*)*",
    name: "404NotFound",
    component: () => import("@/views/404NotFound.vue"),
  },
];

const moduleRoutes = [
  baseRoutes,
  matterRoutes,
  sectorRoutes,
  clientRoutes,
  peopleRoutes,
  blogRoutes,
  deadlineCalculatorRoutes,
  terminologyRoutes,
  hssRoutes,
  matterGroupsGenesisRoutes,
  invitationsRoutes,
  aiRoutes,
];
const routes = [].concat(...moduleRoutes).concat(catchAll);

const createRouter = (app) => {
  const router = _createRouter({
    scrollBehavior: (_to, _from, savedPosition) => {
      return savedPosition || { left: 0, top: 0 };
    },
    history: createWebHistory(),
    base: import.meta.env.BASE_URL,
    routes,
  });

  /** Navigation Guard to make sure still active user */
  router.beforeEach((to, from, next) => {
    // We want to store a stack of up to 5 route history to store
    // Only store if its not null/user loading and not the same route
    // e.g navigate to sector dashboard - clicking sectors won't re add
    if (
      from.path !== null &&
      from.path !== "/loading" &&
      to.path !== from.path
    ) {
      app.config.globalProperties.$store.dispatch(
        "core/addRoutePathToHistory",
        from
      );
    }

    /** Don't try and login again if it is a guest user */
    if (
      Auth.isGuestUser() &&
      !Auth.approvedIdp() &&
      to.path !== UNAUTHORISED_PATH
    ) {
      next({ path: UNAUTHORISED_PATH });
      return;
    }

    if (to.name === "401Unauthorised") {
      next();
      return;
    }

    // If we are on /?login=false then don't do anything else here.
    if (to.query.login === "false") {
      next();
      return;
    }

    if (BrowserCheck.isBrowserIE()) {
      next({ name: "home", query: { login: "false", browser: "ie" } });
      return;
    }

    if (to.path === "/loading" || Auth.isAuthenticated()) {
      // This goes through the matched routes from last to first, finding the closest route with a title.
      // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
      const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r?.meta?.title);

      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) {
        // Replace any variables in the route meta title
        // eslint-disable-next-line sonarjs/slow-regex
        const regexp = /{(.*)}/;
        nearestWithTitle.meta.displayTitle =
          nearestWithTitle.meta.title.replace(regexp, (_match, p1) => {
            return to.params[p1];
          });

        document.title = `${import.meta.env.VITE_NAME} // ${
          nearestWithTitle.meta.displayTitle
        }`;
      }

      next();
      return;
    }

    // Default the target to current path
    let target = to.path;

    if (to.path !== "/loading") {
      // Handle whether redirectedFrom is an object or string
      // Revert back to default if to.redirectedFrom is falsy
      target =
        (typeof to.redirectedFrom === "object"
          ? to.redirectedFrom.path
          : to.redirectedFrom) || to.path;
    }

    // Re-enable this
    // Store.dispatch("core/setRouterLoggingIn", true);

    Auth.login({
      target: target,
    });
  });

  return router;
};

export default (app) => createRouter(app);
